<template>
  <Breadcrumbs />
  <div class="container">
    <BaseTitle>{{ $t("app.title") }}</BaseTitle>

    <!-- The orange container with QR code and app store buttons-->
    <div class="download-app-cta">
      <div class="download-app-text">
        <BaseText class="text-light">{{
          $t("app.download-app-description")
        }}</BaseText>
        <BaseText class="text-light bold">{{
          $t("app.download-app-cta-text")
        }}</BaseText>

        <div class="qr-code-wrapper hide-small">
          <BaseImage
            v-if="host === 'promopromo'"
            class="qr-code"
            src="qrcode-promopromo.png"
            :alt="$t('app.qr-code-alt')"
          />
          <BaseImage
            v-else
            class="qr-code"
            src="qrcode-allefolders.png"
            :alt="$t('app.qr-code-alt')"
          />
        </div>

        <AppStoreButtons />
      </div>
      <div class="download-app-image hide-small">
        <BaseImage
          :src="`app-mockup-${host}.png`"
          class="app-mockup ml-1"
          :alt="$t('app.app-mockup-alt')"
        />
      </div>
    </div>

    <!-- The app ratings -->
    <div class="app-ratings">
      <div class="app-ratings-header mb-2">
        <BaseTitle class="align-center">
          {{ $t("app.reviews-title") }}
          <BaseIcon icon="arrow_forward" class="ml-1" />
        </BaseTitle>
        <BaseText>{{ $t("app.reviews-subtitle") }}</BaseText>
      </div>

      <!-- The average rating and progress bars -->
      <div class="progress-bar-ratings">
        <div class="rating-stars">
          <BaseText class="text-xl semi-bold mb-1">{{
            $t("app.play-store-rating")
          }}</BaseText>
          <BaseStarRating
            class="gold mb-1"
            :rating="Number($t('app.play-store-rating'))"
          />
        </div>
        <div class="progress-bars">
          <BaseProgressbar
            v-for="i in [5, 4, 3, 2, 1]"
            :key="i"
            :progress="Number($t(`app.rating-${i}-star-percentage`))"
            >{{ i }}</BaseProgressbar
          >
        </div>
      </div>
      <BaseText class="review-amount">{{ $t("app.review-amount") }}</BaseText>
    </div>

    <!-- The app reviews-->
    <div class="app-reviews-grid">
      <BaseCard>
        <BaseIcon icon="shopping_bag" :outlined="true" class="size-52" />
        <BaseText class="bold">{{ $t("app.selling-point-1.title") }}</BaseText>
        <BaseText>{{ $t("app.selling-point-1.description") }}</BaseText>
      </BaseCard>
      <BaseCard>
        <BaseIcon icon="local_offer" :outlined="true" class="size-52" />
        <BaseText class="bold">{{ $t("app.selling-point-2.title") }}</BaseText>
        <BaseText>{{ $t("app.selling-point-2.description") }}</BaseText>
      </BaseCard>
      <BaseCard>
        <BaseIcon icon="favorite_border" class="size-52" />
        <BaseText class="bold">{{ $t("app.selling-point-3.title") }}</BaseText>
        <BaseText>{{ $t("app.selling-point-3.description") }}</BaseText>
      </BaseCard>
      <BaseCard>
        <BaseIcon icon="verified" :outlined="true" class="size-52" />
        <BaseText>{{ $t("app.user-review-1") }}</BaseText>
      </BaseCard>
      <BaseCard>
        <BaseIcon icon="verified" :outlined="true" class="size-52" />
        <BaseText>{{ $t("app.user-review-2") }}</BaseText>
      </BaseCard>
      <BaseCard>
        <BaseIcon icon="verified" :outlined="true" class="size-52" />
        <BaseText>{{ $t("app.user-review-3") }}</BaseText>
      </BaseCard>
    </div>
  </div>

  <!-- App screenshots-->
  <div class="app-screenshots">
    <div class="container">
      <BaseImage
        v-for="i in 4"
        :key="i"
        :src="`app-screenshot-${i}-${host}.png`"
        class="app-screenshot"
        :alt="$t(`app.screenshot-${i}-alt`)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { event } from "vue-gtag";
const host = useCurrentHost();

const { mobileOs } = useUserAgent();

// Set the appstore URL based on os and host
const appStoreUrl = ref<string | null>(null);
if (mobileOs.value === "android") {
  if (host.value === "allefolders") {
    appStoreUrl.value =
      "https://play.google.com/store/apps/details?id=com.jafolders.allefolders";
  } else {
    appStoreUrl.value =
      "https://play.google.com/store/apps/details?id=com.jafolders.promopromo";
  }
} else if (mobileOs.value === "iOs") {
  if (host.value === "allefolders") {
    appStoreUrl.value =
      "https://apps.apple.com/nl/app/allefolders/id1086601848/";
  } else {
    appStoreUrl.value =
      "https://apps.apple.com/nl/app/promopromo-folders-van-belgi%C3%AB/id811303467";
  }
}

// Immidiately redirect to appstore if on mobile
if (appStoreUrl.value) {
  // Log analytics event
  event("app-store-redirect", { os: mobileOs.value, host: host.value });

  // Navigate to the appstore
  await navigateTo(appStoreUrl.value, { external: true });
}
</script>

<style lang="scss" scoped>
.download-app-cta {
  background-color: var(--primary);
  border-radius: $border-radius;
  padding: 3.5rem 1.5rem;
  display: flex;

  .download-app-text {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    flex: 1;
  }

  .download-app-image {
    height: 425px;

    margin-right: 2rem;

    .app-mockup {
      height: 100%;
    }
  }
}

.qr-code-wrapper {
  width: 175px;
  padding: 0.5rem 0.5rem 0.25rem 0.5rem;
  border-radius: $border-radius;
  background-color: $light-100;
}

.app-ratings {
  padding: 1.5rem 3rem;

  .app-ratings-header {
    @include for-medium-screen-up {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .align-center {
    display: flex;
    align-items: center;
  }
}

.progress-bar-ratings {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1.5rem;

  @include for-medium-screen-up {
    gap: 3rem;
    flex-direction: row;
    margin-bottom: 0;

    .review-amount {
      display: block;
      text-align: center;
    }
  }

  .rating-stars {
    & > * {
      white-space: nowrap;
    }
  }

  .progress-bars {
    width: 100%;
  }
}

@include for-small-screen {
  .review-amount {
    display: block;
    text-align: center;
    font-weight: 600;
  }
}

.app-reviews-grid {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(395px, 1fr));
  @include for-extra-small-screen {
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
  }
}

.app-screenshots {
  margin-top: 3rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: var(--primary-light);

  .container {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;

    @include for-large-screen {
      justify-content: space-between;
    }
  }
}
</style>
