<template>
  <div class="base-card">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.base-card {
  background-color: $light-100;
  border: 1px solid $light-80;
  overflow: hidden;
  border-radius: $border-radius;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0.75rem;
}
</style>
